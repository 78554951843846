<template>
  <div class="form form-job">
    <v-form
      ref="form"
      class="pa-6"
      v-model="valid"
    >
      <Dialog
        v-if="items[0]"
        :icon="$t('COMMON.DUPLICATE_ICON')"
        :subtitle="$t('COMMON.DUPLICATE') + ': ' +  $tc('JOB', items.length)"
        :title="items[0].title"
        component="form/FormJobDuplication"
        :items="items"
        :value="showDuplicate"
        @close="showDuplicate = false"
        @update="updateItems"
        @select="selectItems"
        overlay-color="transparent"
      />

      <Loading :value="loading" />

      <div class="pa-0">
        <v-row v-if="items.length">
          <v-col cols="8">
            <v-text-field
              dense
              v-model="id"
              disabled
              :label="$t('INPUT.ID')"
              :placeholder="$t('INPUT.ID')"
              outlined
            >
              <template v-slot:append v-if="parent_id">
                <v-icon :title="parent_id" color="success">{{$t('COMMON.DUPLICATE_ICON')}}</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-select
              dense
              v-model="status"
              :items="[{text: $t('INPUT.STATUS_0'), value: 0}, {text: $t('INPUT.STATUS_1'), value: 1}]"
              :label="$t('INPUT.STATUS')"
              :placeholder="$t('INPUT.STATUS')"
              item-value="value"
              item-text="text"
              outlined
            />
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="12" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="title"
              :rules="[$rules.required, $rules.maxlength(255)]"
              :label="$t('INPUT.TITLE')"
              :placeholder="$t('INPUT.TITLE')"
              outlined
              required
            />
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="6" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="category"
              :rules="[$rules.required, $rules.maxlength(255)]"
              :label="$t('INPUT.CATEGORY')"
              :placeholder="$t('INPUT.CATEGORY')"
              outlined
              required
            />
          </v-col>
          <v-col cols="6" class="mt-0 mb-0 text-small">
            <table class="x-small">
              <tr>
                <th>{{$t('JOB.XTRAMILE_SECTOR')}} </th>
                <td><span v-if="xtramile_sector">{{xtramile_sector}} ({{xtramile_sector_id}})</span></td>
              <tr/><tr>
                <th>{{$t('JOB.XTRAMILE_CATEGORY')}} </th>
                <td><span v-if="xtramile_occupation">{{xtramile_occupation}} ({{xtramile_occupation_id}})</span></td>
              </tr>
              <tr>
                <th>{{$t('JOB.ESCO4_CODE')}} </th>
                <td>{{esco4_code}}
                  <a
                    v-if="esco4_code"
                    target="_blank"
                    class="info--text"
                    :href="'https://ec.europa.eu/esco/portal/search?&newLanguage=' + (language || 'fr') + '&term=' + esco4_code"
                  >
                    <v-icon color="info" size="12">mdi-link</v-icon>
                    {{$t('COMMON.SEE')}}
                  </a>
                </td>
              </tr>
              <tr>
                <th>{{$t('JOB.ROME_CODE')}}</th>
                <td>{{rome_code}}
                  <a
                    v-if="rome_code"
                    target="_blank"
                    class="info--text"
                    :href="'http://rome.adem.public.lu/search/?q=' + rome_code"
                  >
                    <v-icon color="info" size="12">mdi-link</v-icon>
                    {{$t('COMMON.SEE')}}
                  </a>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="12" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="url"
              :rules="[$rules.required, $rules.maxlength(255)]"
              :label="$t('INPUT.URL')"
              :placeholder="$t('INPUT.URL')"
              outlined
              required
            >
              <template slot="append">
                <a :href="url" target="_blank"><v-icon size="24" class="pl-2">mdi-open-in-new</v-icon></a>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="12" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="image_url"
              :rules="[$rules.maxlength(255)]"
              :label="$t('INPUT.IMAGE_URL')"
              :placeholder="$t('INPUT.IMAGE_URL')"
              outlined
            />
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="4" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="date"
              :label="$t('INPUT.DATE')"
              :placeholder="$t('INPUT.DATE')"
              outlined
            />
          </v-col>
          <v-col cols="4" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="start_date"
              :label="$t('INPUT.START_DATE')"
              :placeholder="$t('INPUT.START_DATE')"
              outlined
            />
          </v-col>
          <v-col cols="4" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="end_date"
              :label="$t('INPUT.END_DATE')"
              :placeholder="$t('INPUT.END_DATE')"
              outlined
            />
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="12" class="mt-0 mb-0">
            <v-textarea
              dense
              height="300px"
              v-model="description"
              :rules="[$rules.required]"
              :label="$t('INPUT.DESCRIPTION')"
              :placeholder="$t('INPUT.DESCRIPTION')"
              outlined
              required
            />
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="12" class="mt-0 mb-0">
            <v-textarea
              dense
              v-model="profile"
              :label="$t('INPUT.PROFILE')"
              :placeholder="$t('INPUT.PROFILE')"
              outlined
            />
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="12" class="mt-0 mb-0">
            <v-combobox
              dense
              v-model="tags"
              :items="tagItems"
              :label="$t('INPUT.TAGS')"
              :placeholder="$t('INPUT.TAGS')"
              multiple
              small-chips
              outlined
              required
            />
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="5" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="location"
              :label="$t('INPUT.LOCATION')"
              :placeholder="$t('INPUT.LOCATION')"
              outlined
            />
          </v-col>
          <v-col cols="7" class="mt-0 mb-0">
            <table class="x-small">
              <tr>
                <th>{{$t('JOB.LOCATION')}}</th>
                <td>
                  {{ [location_city,location_city_code,location_department,location_region,location_country]
                  .filter(v => !!v)
                  .join(', ') }}
                </td>
              <tr/><tr>
                <th>{{$t('JOB.GEO')}}</th>
                <td>{{location_geoloc}}
                  <a
                    v-if="[location_city,location_city_code,location_department,location_region,location_country]
                    .filter(v => !!v)
                    .length > 0"
                    target="_blank"
                    class="info--text"
                    :href="
                      'https://www.google.com/maps/search/?api=1&zoom=12&query='
                      + encodeURIComponent(location_city
                      + '+'
                      + location_city_code
                      + '+'
                      + location_department
                      + '+'
                      + location_region
                      + '+'
                      + location_country)
                    "
                  >
                    <v-icon color="info" size="12">
                      mdi-map-marker
                    </v-icon>
                    {{$t('COMMON.SEE')}}
                  </a>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="5" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="company"
              :label="$t('INPUT.COMPANY')"
              :placeholder="$t('INPUT.COMPANY')"
              outlined
            />
          </v-col>
          <v-col cols="7" class="mt-0 mb-0 text-small">
            <table class="x-small">
              <tr>
                <th>{{$t('JOB.COMPANY_TITLE')}} </th>
                <td :title="company_title + ':' + company_description">{{company_title}}</td>
              <tr/><tr>
                <th>{{$t('JOB.COMPANY_IMAGE')}} </th>
                <td><a :href="company_image_url" target="_blank">{{company_url}}</a></td>
              </tr>
              <tr>
                <th>{{$t('JOB.COMPANY_URL')}} </th>
                <td><a :href="company_url" target="_blank">{{company_url}}</a></td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="5" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="contact"
              :label="$t('INPUT.CONTACT')"
              :placeholder="$t('INPUT.CONTACT')"
              outlined
            />
          </v-col>
          <v-col cols="7" class="mt-0 mb-0 text-small">
            <table class="x-small">
              <tr>
                <th>{{$t('JOB.CONTACT_NAME')}} </th>
                <td>{{contact_name}} ({{contact_position}})</td>
              <tr/><tr>
                <th>{{$t('JOB.CONTACT_PHONE')}} </th>
                <td>{{contact_phone}}</td>
              </tr>
              <tr>
                <th>{{$t('JOB.CONTACT_EMAIL')}} </th>
                <td><a :mailto="contact_email" target="_blank">{{contact_email}}</a></td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="5" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="salary"
              :rules="[$rules.maxlength(255)]"
              :label="$t('JOB.SALARY')"
              :placeholder="$t('JOB.SALARY')"
              outlined
            />
          </v-col>
          <v-col cols="7" class="mt-0 mb-0 text-small">
            <table class="x-small">
              <tr>
                <th>{{$t('JOB.SALARY')}} - {{$t('JOB.MIN')}} </th>
                <td>{{salary_currency}} {{salary_min}} / {{salary_period}}</td>
              <tr/><tr>
                <th>{{$t('JOB.SALARY')}} - {{$t('JOB.MAX')}} </th>
                <td>{{salary_currency}} {{salary_max}} / {{salary_period}}</td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="5" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="age"
              :rules="[$rules.maxlength(255)]"
              :label="$t('INPUT.AGE')"
              :placeholder="$t('INPUT.AGE')"
              outlined
            />
          </v-col>
          <v-col cols="7" class="mt-0 mb-0 text-small">
            <table class="x-small">
              <tr>
                <th>{{$t('JOB.AGE')}} - {{$t('JOB.MIN')}} </th>
                <td>{{age_min}}</td>
              <tr/><tr>
                <th>{{$t('JOB.AGE')}} - {{$t('JOB.MAX')}} </th>
                <td>{{age_max}}</td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="5" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="contract"
              :label="$t('INPUT.CONTRACT')"
              :placeholder="$t('INPUT.CONTRACT')"
              outlined
            />
          </v-col>
          <v-col cols="7" class="mt-0 mb-0 text-small">
            <table class="x-small">
              <tr>
                <th>{{$t('JOB.CONTRACT_TYPE')}} </th>
                <td>{{contract_type}}</td>
              <tr/><tr>
                <th>{{$t('JOB.CONTRACT_SCHEDULE')}} </th>
                <td>{{contract_schedule}}</td>
              </tr>
              <tr>
                <th>{{$t('JOB.CONTRACT_DETAILS')}} </th>
                <td>{{$tc('JOB.CONTRACT_REMOTE', +contract_remote)}}, {{contract_status}}</td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="5" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="experience"
              :label="$t('INPUT.EXPERIENCE')"
              :placeholder="$t('INPUT.EXPERIENCE')"
              outlined
            />
          </v-col>
          <v-col cols="7" class="mt-0 mb-0 text-small">
            <table class="x-small">
              <tr>
                <th>{{$t('JOB.EXPERIENCE_LEVEL')}} </th>
                <td>{{experience_level}}</td>
              <tr/><tr>
                <th>{{$t('JOB.EXPERIENCE_DOMAINS')}} </th>
                <td>{{experience_domains}}</td>
              </tr>
              <tr>
                <th>{{$t('JOB.MIN')}} / {{$t('JOB.MAX')}} </th>
                <td>{{experience_min}} {{experience_min_period}} / {{experience_max}} {{experience_max_period}}</td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col cols="5" class="mt-0 mb-0">
            <v-text-field
              dense
              v-model="education"
              :label="$t('INPUT.EDUCATION')"
              :placeholder="$t('INPUT.EDUCATION')"
              outlined
            />
          </v-col>
          <v-col cols="7" class="mt-0 mb-0 text-small">
            <table class="x-small">
              <tr>
                <th>{{$t('JOB.EDUCATION_LEVEL')}} </th>
                <td>{{education_level}}</td>
              <tr/><tr>
                <th>{{$t('JOB.EDUCATION_DOMAINS')}} </th>
                <td>{{education_domains}}</td>
              </tr>
              <tr>
                <th>{{$t('JOB.DIPLOMAS')}}, {{$t('JOB.EDUCATION_LANGUAGES')}} </th>
                <td>{{diplomas}} {{education_languages}}</td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <v-divider />
    <v-toolbar
      class="white"
      flat
      height="70px"
    >
      <v-btn
        @click="resetItem"
        dark
        fab
        x-small
        depressed
        color="warning"
        class="mr-3"
      >
        <v-icon>mdi-backup-restore</v-icon>
      </v-btn>
      <v-btn
        v-if="items.length"
        @click="removeItem"
        dark
        fab
        x-small
        depressed
        color="error"
        class="mr-3"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="items.length"
        @click="parseItem"
        dark
        fab
        x-small
        depressed
        color="info"
        class="mr-3"
      >
        <v-icon>mdi-head-sync</v-icon>
      </v-btn>
      <v-btn
        v-if="items.length"
        @click="showDuplicate = true"
        dark
        fab
        x-small
        depressed
        color="indigo"
      >
        <v-icon>{{$t('COMMON.DUPLICATE_ICON')}}</v-icon>
      </v-btn>
      <v-chip
        v-if="status_code"
        outlined
        class="ml-3 pl-1"
      >
        <v-icon
          size="24"
          class="mr-2"
          :color="status_type"
        >
          {{$t(status_code + '_ICON')}}
        </v-icon>
        <span :title="status_message">{{$t(status_code)}}</span>
      </v-chip>
      <v-spacer />
      <v-btn
        color="success custom"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <v-icon class="mr-2">mdi-check-bold</v-icon>
        <strong>{{$t(items.length ? 'COMMON.APPLY' : 'COMMON.CREATE')}}</strong>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import Dialog from '@/components/Dialog'

const _isSame = (v1, v2) => {
  if (typeof v1 === 'object') {
    return JSON.stringify(v1) === JSON.stringify(v2)
  } else {
    return v1 === v2
  }
}

const _compare = (v1, v2, fields) => {
  const diff = {}
  fields.forEach(field => {
    if (field.includes('date')) {
      if (new Date(v1[field] || null).toISOString() !== new Date(v2[field] || null)
        .toISOString()) diff[field] = v2[field]
    } else {
      if (!_isSame(v1[field], v2[field])) diff[field] = v2[field]
    }
  })
  return diff
}


export default {
  name: 'FormJob',
  components: {
    Loading,
    Dialog
  },
  data: (_this) => ({
    loading: false,
    showDuplicate: false,
    valid: false,
    periodItems: [
      { text: _this.$i18n.t('INPUT.PERIOD_YEAR'), value: 'year' },
      { text: _this.$i18n.t('INPUT.PERIOD_MONTH'), value: 'month' },
      { text: _this.$i18n.t('INPUT.PERIOD_DAY'), value: 'day' },
      { text: _this.$i18n.t('INPUT.PERIOD_HOUR'), value: 'hour' },
    ],
    contractTimeItems: [
      { text: _this.$i18n.t('INPUT.CONTRACT_SCHEDULE_FULL_TIME'), value: 'full_time' },
      { text: _this.$i18n.t('INPUT.CONTRACT_SCHEDULE_PART_TIME'), value: 'part_time' },
      { text: _this.$i18n.t('INPUT.CONTRACT_SCHEDULE_FULL_PART_TIME'), value: 'full_part_time' },
      { text: _this.$i18n.t('INPUT.CONTRACT_SCHEDULE_ONE_SHOT'), value: 'one_shot' },
    ],
    contractTypeItems: [
      { text: _this.$i18n.t('INPUT.CONTRACT_TYPE_PERMANENT'), value: 'permanent' },
      { text: _this.$i18n.t('INPUT.CONTRACT_TYPE_TEMPORARY'), value: 'temporary' },
      { text: _this.$i18n.t('INPUT.CONTRACT_TYPE_INTERIM'), value: 'interim' },
      { text: _this.$i18n.t('INPUT.CONTRACT_TYPE_INTERNSHIP'), value: 'internship' },
      { text: _this.$i18n.t('INPUT.CONTRACT_TYPE_WORK_STUDY'), value: 'work_study' },
      { text: _this.$i18n.t('INPUT.CONTRACT_TYPE_INTERMITTENT'), value: 'intermittent' },
      { text: _this.$i18n.t('INPUT.CONTRACT_TYPE_OFFICIAL'), value: 'official' },
      { text: _this.$i18n.t('INPUT.CONTRACT_TYPE_FREELANCE'), value: 'freelance' },
      { text: _this.$i18n.t('INPUT.CONTRACT_TYPE_SEASONAL'), value: 'seasonal' },
    ],
    tagItems: [],
    // form
    id: undefined,
    feed_id: undefined,
    parent_id: undefined,
    status: 1,
    status_code: null,
    status_type: null,
    status_message: null,
    ref: undefined,
    title: undefined,
    intro: undefined,
    description: undefined,
    profile: undefined,
    url: undefined,
    image_url: undefined,
    images: undefined,
    category: undefined,
    xtramile_sector: undefined,
    xtramile_sector_id: undefined,
    xtramile_occupation: undefined,
    xtramile_occupation_id: undefined,
    esco4_code: undefined,
    rome_code: undefined,
    locations: [],
    location: undefined,
    location_country: undefined,
    location_country_code: undefined,
    location_region: undefined,
    location_region_code: undefined,
    location_department: undefined,
    location_department_code: undefined,
    location_city: undefined,
    location_city_code: undefined,
    location_geoloc: undefined,
    quantity: undefined,
    date: undefined,
    start_date: undefined,
    end_date: undefined,
    need_refresh: undefined,
    language: undefined,
    age: undefined,
    age_min: undefined,
    age_max: undefined,
    contract: undefined,
    contract_type: undefined,
    contract_schedule: undefined,
    contract_remote: undefined,
    contract_status: undefined,
    duration: undefined,
    duration_min: undefined,
    duration_max: undefined,
    duration_min_period: undefined,
    duration_max_period: undefined,
    salary: undefined,
    salary_min: undefined,
    salary_max: undefined,
    salary_period: undefined,
    salary_currency: undefined,
    education: undefined,
    education_level: undefined,
    education_languages: [],
    education_domains: [],
    experience: undefined,
    experience_level: undefined,
    experience_domains: [],
    experience_min: undefined,
    experience_max: undefined,
    experience_max_period: undefined,
    experience_min_period: undefined,
    company: undefined,
    company_title: undefined,
    company_description: undefined,
    company_image_url: undefined,
    company_url: undefined,
    contact: undefined,
    contact_name: undefined,
    contact_position: undefined,
    contact_phone: undefined,
    contact_email: undefined,
    diplomas: undefined,
    skills: [],
    skills_esco4_code: [],
    skills_rome_code: [],
    advantages: [],
    tags: [],
  }),
  props: {
    props: {
      type: [Object, Boolean],
      default: false,
    },
    items: {
      type: [Array, Boolean],
      default: false,
    },
  },
  watch: {
    loading (n) {
      this.$emit('loading', n)
    },
    items (n) {
      this.assignItems(n)
    }
  },
  computed: {
  },
  mounted () {
    this.assignItems(this.items)
  },

  methods: {

    resetItem () {
      if (this.items.length === 1) {
        this.assignItems(this.items)
      } else {
        this.parent_id = undefined
        this.feed_id = undefined
        this.status = 1
        this.ref = undefined
        this.title = undefined
        this.intro = undefined
        this.description = undefined
        this.profile = undefined
        this.url = undefined
        this.image_url = undefined
        this.images = undefined
        this.category = undefined
        this.xtramile_sector = undefined
        this.xtramile_sector_id = undefined
        this.xtramile_occupation = undefined
        this.xtramile_occupation_id = undefined
        this.esco4_code = undefined
        this.rome_code = undefined
        this.locations = []
        this.location = undefined
        this.location_country = undefined
        this.location_country_code = undefined
        this.location_region = undefined
        this.location_region_code = undefined
        this.location_department = undefined
        this.location_department_code = undefined
        this.location_city = undefined
        this.location_city_code = undefined
        this.location_geoloc = undefined
        this.quantity = undefined
        this.date = undefined
        this.start_date = undefined
        this.end_date = undefined
        this.need_refresh = undefined
        this.language = undefined
        this.age = undefined
        this.age_min = undefined
        this.age_max = undefined
        this.contract = undefined
        this.contract_type = undefined
        this.contract_schedule = undefined
        this.contract_remote = undefined
        this.contract_status = undefined
        this.duration = undefined
        this.duration_min = undefined
        this.duration_max = undefined
        this.duration_min_period = undefined
        this.duration_max_period = undefined
        this.salary = undefined
        this.salary_min = undefined
        this.salary_max = undefined
        this.salary_period = undefined
        this.salary_currency = undefined
        this.education = undefined
        this.education_level = undefined
        this.education_languages = []
        this.education_domains = []
        this.experience = undefined
        this.experience_level = undefined
        this.experience_domains = []
        this.experience_min = undefined
        this.experience_max = undefined
        this.experience_max_period = undefined
        this.experience_min_period = undefined
        this.company = undefined
        this.company_title = undefined
        this.company_description = undefined
        this.company_image_url = undefined
        this.company_url = undefined
        this.contact = undefined
        this.contact_name = undefined
        this.contact_position = undefined
        this.contact_phone = undefined
        this.contact_email = undefined
        this.diplomas = []
        this.skills = []
        this.skills_esco4_code = []
        this.skills_rome_code = []
        this.advantages = []
        this.tags = []
      }
    },

    assignItems (items) {
      if (items.length > 0) {
        const data = items[0]
        this.id = data.id
        this.parent_id = data.parent_id
        this.feed_id = data.feed_id
        this.status = data.status
        this.status_type = data.status_type
        this.status_code = data.status_code
        this.status_message = data.status_message
        this.ref = data.ref
        this.title = data.title
        this.intro = data.intro
        this.description = data.description
        this.profile = data.profile
        this.url = data.url
        this.image_url = data.image_url
        this.images = data.images
        this.category = data.category
        this.xtramile_sector = data.xtramile_sector
        this.xtramile_sector_id = data.xtramile_sector_id
        this.xtramile_occupation = data.xtramile_occupation
        this.xtramile_occupation_id = data.xtramile_occupation_id
        this.esco4_code = data.esco4_code
        this.rome_code = data.rome_code
        this.locations = data.locations
        this.location = data.location
        this.location_country = data.location_country
        this.location_country_code = data.location_country_code
        this.location_region = data.location_region
        this.location_region_code = data.location_region_code
        this.location_department = data.location_department
        this.location_department_code = data.location_department_code
        this.location_city = data.location_city
        this.location_city_code = data.location_city_code
        this.location_geoloc = data.location_geoloc
        this.quantity = data.quantity
        this.date = data.date
        this.start_date = data.start_date
        this.end_date = data.end_date
        this.need_refresh = data.need_refresh
        this.language = data.language
        this.age = data.age
        this.age_min = data.age_min
        this.age_max = data.age_max
        this.contract = data.contract
        this.contract_type = data.contract_type
        this.contract_schedule = data.contract_schedule
        this.contract_remote = data.contract_remote
        this.contract_status = data.contract_status
        this.duration = data.duration
        this.duration_min = data.duration_min
        this.duration_max = data.duration_max
        this.duration_min_period = data.duration_min_period
        this.duration_max_period = data.duration_max_period
        this.salary = data.salary
        this.salary_min = data.salary_min
        this.salary_max = data.salary_max
        this.salary_period = data.salary_period
        this.salary_currency = data.salary_currency
        this.education = data.education
        this.education_level = data.education_level
        this.education_languages = data.education_languages
        this.education_domains = data.education_domains
        this.experience = data.experience
        this.experience_level = data.experience_level
        this.experience_domains = data.experience_domains
        this.experience_min = data.experience_min
        this.experience_max = data.experience_max
        this.experience_max_period = data.experience_max_period
        this.experience_min_period = data.experience_min_period
        this.company = data.company
        this.company_title = data.company_title
        this.company_description = data.company_description
        this.company_image_url = data.company_image_url
        this.company_url = data.company_url
        this.contact = data.contact
        this.contact_name = data.contact_name
        this.contact_position = data.contact_position
        this.contact_phone = data.contact_phone
        this.contact_email = data.contact_email
        this.diplomas = data.diplomas
        this.skills = data.skills
        this.skills_esco4_code = data.skills_esco4_code
        this.skills_rome_code = data.skills_rome_code
        this.advantages = data.advantages
        this.tags = data.tags || []
        //
        this.tagItems = [...this.tags]
      }
    },


    validate () {
      if (this.$refs.form.validate()) {
        if (this.items.length > 0) return this.updateItem()
        return this.createItem()
      }
    },


    createItem () {
      // prepare values
      const values = {
        feed_id: this.feed_id,
        status: this.status,
        status_type: this.status_type,
        status_code: this.status_code,
        status_message: this.status_message,
        ref: this.ref,
        title: this.title,
        intro: this.intro,
        description: this.description,
        profile: this.profile,
        url: this.url,
        image_url: this.image_url,
        images: this.images,
        category: this.category,
        xtramile_sector: this.xtramile_sector,
        xtramile_sector_id: this.xtramile_sector_id,
        xtramile_occupation: this.xtramile_occupation,
        xtramile_occupation_id: this.xtramile_occupation_id,
        esco4_code: this.esco4_code,
        rome_code: this.rome_code,
        locations: this.locations,
        location: this.location,
        location_country: this.location_country,
        location_country_code: this.location_country_code,
        location_region: this.location_region,
        location_region_code: this.location_region_code,
        location_department: this.location_department,
        location_department_code: this.location_department_code,
        location_city: this.location_city,
        location_city_code: this.location_city_code,
        location_geoloc: this.location_geoloc,
        quantity: this.quantity,
        date: this.date,
        start_date: this.start_date,
        end_date: this.end_date,
        need_refresh: this.need_refresh,
        language: this.language,
        age: this.age,
        age_min: this.age_min,
        age_max: this.age_max,
        contract: this.contract,
        contract_type: this.contract_type,
        contract_schedule: this.contract_schedule,
        contract_remote: this.contract_remote,
        contract_status: this.contract_status,
        duration: this.duration,
        duration_min: this.duration_min,
        duration_max: this.duration_max,
        duration_min_period: this.duration_min_period,
        duration_max_period: this.duration_max_period,
        salary: this.salary,
        salary_min: this.salary_min,
        salary_max: this.salary_max,
        salary_period: this.salary_period,
        salary_currency: this.salary_currency,
        education: this.education,
        education_level: this.education_level,
        education_languages: this.education_languages,
        education_domains: this.education_domains,
        experience: this.experience,
        experience_level: this.experience_level,
        experience_domains: this.experience_domains,
        experience_min: this.experience_min,
        experience_max: this.experience_max,
        experience_max_period: this.experience_max_period,
        experience_min_period: this.experience_min_period,
        company: this.company,
        company_title: this.company_title,
        company_description: this.company_description,
        company_image_url: this.company_image_url,
        company_url: this.company_url,
        contact: this.contact,
        contact_name: this.contact_name,
        contact_position: this.contact_position,
        contact_phone: this.contact_phone,
        contact_email: this.contact_email,
        diplomas: this.diplomas,
        skills: this.skills,
        skills_esco4_code: this.skills_esco4_code,
        skills_rome_code: this.skills_rome_code,
        advantages: this.advantages,
        tags: this.tags,
      }
      const query = {
        values
      }
      this.loading = true
      this.$services.api_programmatic.job.create(query)
        .then(response => {
          this.$emit('update')
          this.$emit('select', [response.data])
        })
        .catch(response => {
          this.$store.dispatch('messenger/add', {type: 'error', code: response.code, message: response.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    removeItem () {
      const ids = this.items.map(v => v.id)
      this.$services.api_programmatic.job.remove({ where: { id: ids }})
        .then(response => {
          this.$emit('update')
          this.$emit('select', [])
          this.$emit('close')
        })
    },

    confirmDuplicateItem () {
      const ids = this.items.map(v => v.id)
      this.$services.api_programmatic.job.duplicate({ where: { id: ids }})
        .then(response => {
          this.$emit('update')
          this.$emit('select', [])
          this.$emit('close')
        })
    },

    parseItem () {
      const ids = this.items.map(v => v.id)
      this.$services.api_programmatic.job.refresh({ where: { id: ids }})
        .then(response => {
          this.$emit('update')
        })
    },

    updateItem () {
      // prepare values
      const values = []
      const fields = ['feed_id', 'status', 'status_type', 'status_code', 'status_message', 'ref', 'title', 'intro', 'description', 'profile', 'url', 'image_url', 'images', 'category', 'xtramile_sector', 'xtramile_sector_id', 'xtramile_occupation', 'xtramile_occupation_id', 'esco4_code', 'rome_code', 'locations', 'location', 'location_country', 'location_country_code', 'location_region', 'location_region_code', 'location_department', 'location_department_code', 'location_city', 'location_city_code', 'location_geoloc', 'quantity', 'date', 'start_date', 'end_date', 'need_refresh', 'language', 'age', 'age_min', 'age_max', 'contract', 'contract_type', 'contract_schedule', 'contract_remote', 'contract_status', 'duration', 'duration_min', 'duration_max', 'duration_min_period', 'duration_max_period', 'salary', 'salary_min', 'salary_max', 'salary_period', 'salary_currency', 'education', 'education_level', 'education_languages', 'education_domains', 'experience', 'experience_level', 'experience_domains', 'experience_min', 'experience_max', 'experience_max_period', 'experience_min_period', 'company', 'company_title', 'company_description', 'company_image_url', 'company_url', 'contact', 'contact_name', 'contact_position', 'contact_phone', 'contact_email', 'diplomas', 'skills', 'skills_esco4_code', 'skills_rome_code', 'advantages', 'tags']
      this.items.forEach((item, i) => {
        values.push({
          values: _compare(item, this, fields),
          where: { id: item.id }
        })
      })
      this.loading = true
      this.$services.api_programmatic.job.update({values})
        .then(response => {
          this.loading = false
          this.$emit('update')
        })
        .catch(response => {
          this.$store.dispatch('messenger/add', {type: 'error', code: response.code, message: response.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    updateItems () {
      this.$emit('update')
    },

    selectItems (items) {
      this.$emit('select', items)
    },
  }
}
</script>
